<template>
  <section>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <div class="text-left">
        <div
          v-for="(item,index) in motorFormList"
          :key="index"
          class="form-item form-half">
          <template v-if="!item.noshow">
            <div class="flex-col text-left">
              <span :content="item.en_content">{{item.en}}</span>
              <span :content="item.zh_content">{{item.zh}}</span>
            </div>
            <el-form-item
              :ref="`vehicle_detail.${item.key}`"
              :rules="rules[item.key]"
              :prop="`vehicle_detail.${item.key}`"
            >
              <template v-if="item.type==1">
                <v-input
                  v-model="formData.vehicle_detail[item.key]"
                  :keyNumber="item.keyNumber"
                  :money="item.keyFloatNumber"
                  :type="item.keyType"
                  :is-upper="item.key=='vehicle_cylinder_capacity'?false:true"
                  :max="item.max"
                  :suffixText="item.append">
                </v-input>
              </template>
              <template v-if="item.type==2">
                <v-select
                  v-model="formData.vehicle_detail[item.key]"
                  :labelKey="item.labelKey||'name'"
                  :list="item.list"></v-select>
              </template>
              <template v-if="item.type==3">
                <v-radio-group
                  v-model="formData.vehicle_detail[item.key]"
                  block
                  border
                  :labelKey="item.labelKey||'name'"
                  :list="item.list"></v-radio-group>
              </template>

            </el-form-item>
          </template>
        </div>
        <v-button @click="handleNext">下一步 Next</v-button>
      </div>
    </el-form>
  </section>

</template>

<script>
import { mapState, mapActions } from 'vuex'
import MixinsPolicyForm from '@/mixins/policyForm'
import MixinsOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
export default {
  name: 'insure-motor-detail-form',
  mixins:[MixinsOptions,MixinRules,MixinsPolicyForm],
  data() {
    const checkMax = (rule, value, callback) => {
      const {vehicle_detail} = this.formData||{}
      const {body_type_id,vehicle_cylinder_capacity} = vehicle_detail||{}
      // if(body_type_id==21&&vehicle_cylinder_capacity>650) {
      //   callback(new Error('請檢查所輸入的數值\n' +
      //     'Please check the entered value'))
      // }
      // if(body_type_id!=21&&vehicle_cylinder_capacity>1999) {
      //   callback(new Error('請檢查所輸入的數值\n' +
      //     'Please check the entered value'))
      // }
      if(vehicle_cylinder_capacity>9999||vehicle_cylinder_capacity=='') {
        callback(new Error('請檢查所輸入的數值\n' +
          'Please check the entered value'))
      }
      callback()
    }
    return {
      nextPath:'/quoting/insure/upload',
      formData:{
        vehicle_detail:{
          registration_mark: '',
          searing_capacity: 0,
          car_make_id: '',
          // chassis_number: '',
          car_model: '',
          body_type_id: '',//i
          // engine_number: '',
          ncdShow: '',//i,
          vehicle_cylinder_capacity: '',//i
          year_of_manufacture: '',
          // ncd:0,
          // previous_insurance_company_id:'',
          // is_same_previous_car:'',
          // policy_number:'',
          // registration_number:'',
          is_delivery_purpose:'',
          is_modification:'',
          is_install_camcorder:'',
        }
      },
      rules: {
        vehicle_cylinder_capacity: [
          { validator: checkMax, trigger: ['blur','change'] }
        ]
      },
    }
  },
  computed:{

  },
  watch:{
    quoteInformationForm:{
      immediate:true,
      handler(val){
        let {vehicle_detail} = val||{}
        Object.keys(this.formData.vehicle_detail).some(key=>{
          if(vehicle_detail&&vehicle_detail[key]){
            this.formData.vehicle_detail[key] = vehicle_detail[key]
          }

        })
      }
    },
    'formData.vehicle_detail.body_type_id':{
      immediate:true,
      handler(val){
        let {vehicle_detail} = this.formData||{}
        let {vehicle_cylinder_capacity} = vehicle_detail||{}
        if(val==43){

          // this.formData.vehicle_detail.vehicle_cylinder_capacity = vehicle_cylinder_capacity>650?650:vehicle_cylinder_capacity
          // this.formData.vehicle_detail.vehicle_cylinder_capacity = val==21?650:999
        }else {
          const regex = /\./
          if(regex.test(vehicle_cylinder_capacity)){
            this.formData.vehicle_detail.vehicle_cylinder_capacity =''
          }
          // this.formData.vehicle_detail.vehicle_cylinder_capacity = vehicle_cylinder_capacity>999?999:vehicle_cylinder_capacity
        }
      }
    }
  },
}
</script>

<style scoped>

</style>
